var generic = generic || {};
var site = site || {};
var Mustache = Mustache || {};

(function ($) {
  site.offers = site.offers || {};
  site.offers.welcomeoffer = site.offers.welcomeoffer || {};

  // --------
  // Primary behavior responsible for initializing the welcome offer logic
  Drupal.behaviors.ELB_welcomeoffer = {
    // debug config
    debug: false,
    showThanks: true,
    showSignedUp: false,

    // storage cookie name
    offerCookie: 'pc_email_optin',

    // template path config
    templates: {
      form: 'contact_us',
      thanks: 'contact_us_success'
    },

    // overlay config
    overlay: {
      pc: {
        className: 'contact-us-overlay',
        transition: 'none',
        width: '450',
        height: '600'
      },
      mobile: {
        className: 'contact-us-overlay',
        width: '100%',
        height: '100%',
        opacity: '0',
        transition: 'none'
      }
    },

    isStr: function (str) {
      return typeof str === 'string';
    },

    isFunc: function (func) {
      return typeof func === 'function';
    },

    isObj: function (mixed_var) {
      if (Object.prototype.toString.call(mixed_var) === '[object Array]') {
        return false;
      }

      return mixed_var !== null && typeof mixed_var === 'object';
    },

    /**
     * Helper function to get the raw contents of an JS inline template, and optionally interpolate
     * the html using Mustache
     */
    getTemplateContent: function (key, data) {
      // Sanitize the incoming data
      data = data || false;

      // Get the html content of the template
      var html = site.template.get({
        name: key,
        data: data
      });

      if (html.length === 0) {
        return $();
      }

      // If there's no data object passed in, then just return a basic jquery element
      if (data === false || typeof Mustache.render !== 'function') {
        return $(html);
      }

      // We have html and data, which means we should use Mustache to render the output
      return $(Mustache.render(html, data));
    },

    // Open a colorbox window
    openWindow: function (content, callBacks) {
      var self = this;

      content = self.isObj(content) ? content : $();

      // Dumb trick to get the entire contents of the html out of the jquery object
      content = content.wrap('<div />').parent().html();

      if (typeof $.colorbox !== 'function') {
        return false;
      }

      var args = {
        html: content,
        height: 'auto',
        maxWidth: '100%',
        maxHeight: '100%'
      };
      var darg = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;

      $.extend(args, darg);

      if (self.isObj(callBacks)) {
        for (var k in callBacks) {
          var func = callBacks[k];

          if (self.isFunc(func)) {
            args[k] = func;
          }
        }
      }

      $.colorbox(args);
    },

    // Show the signup form
    showSignupForm: function () {
      var self = this;

      setTimeout(function () {
        var content = self.getTemplateContent(self.templates.form);
        var useAltMarkUp = $(content).find('#USE_ALT_LAYOUT').length;

        if (useAltMarkUp) {
          // TODO: should cboxArgs be global?
          var cboxArgs = $('body').hasClass('device-mobile')
            ? self.overlay.mobile
            : self.overlay.pc;

          cboxArgs.className = 'contact-us-overlay alt_layout1';
          cboxArgs.fixed = true;
        }

        self.openWindow(content, {
          // Hide the content and overlay in onOpen, so we can fade it in initSignUpForm
          // This prevents "sliding" while the overridden css is loading, and also makes the overlay and content fade in simultaneously
          onOpen: function () {
            $('.contact-us-overlay #cboxContent, #cboxOverlay').css('visibility', 'hidden');
          },
          onComplete: function () {
            site.offers.welcomeoffer.initSignUpForm();
            // This fixes a bug on the m. domain where the colorbox
            // would take up the full height on mobile devices.
            if ($('body').hasClass('device-mobile')) {
              $.colorbox.resize();
            }

            // TODO: this needs to use an event, not call the behavior
            Drupal.behaviors.adaptivePlaceholders.attach($('#cboxLoadedContent'));
          }
        });
      }, self.defaultTimeout);
    },

    // Main function to get things kicked off
    attach: function (context) {
      var self = this,
        cookieName = self.offerCookie;

      // do not show on access denied page
      if ($('body').hasClass('content-gate-access-denied-page')) {
        return;
      }

      $('.js-cs-content-item-block__cta').on('click', function () {
        self.showSignupForm();
      });

      // Create the cookie
      $.cookie(cookieName, '1', {
        expires: 14,
        path: '/'
      });

      // Bind to the contact_us.success event
      // When a user does the email sign up in the footer, we'll show them the welcomeoffer overlay
      // if the offer is still valid, and they haven't signed up already
      $(context).on('contact_us.success', function () {
        var templates = self.templates,
          content = '';

        content = templates.thanks;
        if (content.length > 0) {
          // @TODO: triggering an overlay will close the special-offers-tray. We don't want it to do that.
          var html = self.getTemplateContent(content);

          self.openWindow(html, {
            onComplete: function () {
              $.colorbox.resize();
            }
          });
        }
      });
    }
  };

  // --------
  // Callbacks and whatnot

  site.offers.welcomeoffer = {
    behavior: Drupal.behaviors.ELB_welcomeoffer,

    getOverlayContent: function () {
      return $('#cboxLoadedContent');
    },

    /**
     * Initialize the signup form
     */
    initSignUpForm: function () {
      var self = site.offers.welcomeoffer,
        overlay = self.getOverlayContent();

      $('.contact-us-overlay #cboxContent, #cboxOverlay').fadeOut(1, function () {
        $('#cboxOverlay')
          .css('visibility', 'visible')
          .fadeIn(500, function () {
            $('.contact-us-overlay #cboxContent').css('visibility', 'visible').fadeIn(100);
          });
      });

      overlay.find('form').on('submit', function (e) {
        e.preventDefault();

        var form = this;
        var paramObj = {};

        $.each($(form).serializeArray(), function (index, kv) {
          paramObj[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
        });
        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [paramObj],
          onSuccess: function () {
            var b = self.behavior,
              t = b.templates,
              c = t.error, // Default template to return
              e = true, // Defaults to the error condition
              d = { message: "<p>There's been an error.</p>" }; // Error message

            c = t.thanks;
            // And make sure there's no error to show
            e = false;
            overlay.empty().append(e ? b.getTemplateContent(c, d) : b.getTemplateContent(c));
            $.colorbox.resize();
            $('#colorbox').addClass('success');
            // Act like we're opening a new colorbox
            $(document).trigger('cbox_complete');
          },
          onFailure: function (jsonRpcResponse) {
            // Get errors back and build a set of list items to inject into the HTML.
            var messages = jsonRpcResponse.getMessages();
            var errorHTML = '';
            var errorOrder = [
              'required.first_name.contact_us',
              'required.last_name.contact_us',
              'required.email_address.contact_us'
            ];
            var $emailError = $('.js-contact-us__error-messages');

            $emailError.empty();
            overlay.find('input, textarea').removeClass('error');

            messages = jQuery.grep(messages, function (a) {
              return a.key !== '' && a.key !== 'system.error';
            });

            messages.sort(function (a, b) {
              return errorOrder.indexOf(a.key) - errorOrder.indexOf(b.key);
            });

            for (var i = 0; i < messages.length; i++) {
              var myErr = messages[i];

              if (myErr && myErr.key) {
                // Use the jsonRpcResponse tags to pair with input nodes.
                // Error tags are returned with details of which form, field are tagged to that particular error message
                var fieldName = myErr.tags[2].substr(6).toUpperCase();
                var $node = $('[name=' + fieldName + ']', overlay);

                if ($node.length && !$node.hasClass('error')) {
                  errorHTML += '<li class="' + myErr.key + '">' + myErr.text + '</li>';
                  $node.addClass('error');
                }
              }
            }
            $emailError.html(errorHTML).removeClass('hidden');
          }
        });

        return false;
      });
    }
  };
})(jQuery);
